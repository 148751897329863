<template>
    <div class="container clearfix" v-if="company">
        <div class="poll__first " v-if="!success">
            <div v-if="!rating">
                <!--                <h1 class="poll__header">{{ company.page.question }}</h1>-->
                <span class="poll__header"
                      style="margin-block-end: 0.67em; margin-block-start: 0.67em;">{{ company.page.question }}</span>
                <div class="poll__rating mt-0">
                    <span class="poll__span">Ужасно</span>
                    <div class="poll__emoji" v-if="company.page.type == 1">
                        <div class="block-star">
                            <img :class="'star' + (rating == 1? ' selected': '')"
                                 :src="url + 'cdn/images/' + (rating == 1? 'star-red': 'star') + '.svg'" alt=""
                                 @click="choiceRating(1)">
                            <span @click="choiceRating(1)">1</span>
                        </div>
                        <div class="block-star">
                            <img :class="'star' + (rating == 2? ' selected': '')"
                                 :src="url + 'cdn/images/' + (rating == 2? 'star-red': 'star') + '.svg'" alt=""
                                 @click="choiceRating(2)">
                            <span @click="choiceRating(2)">2</span>
                        </div>
                        <div class="block-star">
                            <img :class="'star' + (rating == 3? ' selected': '')"
                                 :src="url + 'cdn/images/' + (rating == 3? 'star-red': 'star') + '.svg'" alt=""
                                 @click="choiceRating(3)">
                            <span @click="choiceRating(3)">3</span>
                        </div>
                        <div class="block-star">
                            <img :class="'star' + (rating == 4? ' selected': '')"
                                 :src="url + 'cdn/images/' + (rating == 4? 'star-red': 'star') + '.svg'" alt=""
                                 @click="choiceRating(4)">
                            <span @click="choiceRating(4)">4</span>
                        </div>
                        <div class="block-star">
                            <img :class="'star' + (rating == 5? ' selected': '')"
                                 :src="url + 'cdn/images/' + (rating == 5? 'star-red': 'star') + '.svg'" alt=""
                                 @click="choiceRating(5)">
                            <span @click="choiceRating(5)">5</span>
                        </div>

                    </div>
                    <div class="poll__emoji" v-if="company.page.type == 2">
                        <img :class="'emoji' + (rating == 1? ' selected': '')" :src="url + 'cdn/images/e1.svg'"
                             @click="choiceRating(1)">
                        <img :class="'emoji' + (rating == 2? ' selected': '')" :src="url + 'cdn/images/e2.svg'"
                             @click="choiceRating(2)">
                        <img :class="'emoji' + (rating == 3? ' selected': '')" :src="url + 'cdn/images/e3.svg'"
                             @click="choiceRating(3)">
                        <img :class="'emoji' + (rating == 4? ' selected': '')" :src="url + 'cdn/images/e4.svg'"
                             @click="choiceRating(4)">
                        <img :class="'emoji' + (rating == 5? ' selected': '')" :src="url + 'cdn/images/e5.svg'"
                             @click="choiceRating(5)">
                    </div>

                    <div class="span-mobile">
                        <span class="poll__span mobile">Ужасно</span>
                        <span class="poll__span mobile">Отлично</span>
                    </div>
                    <span class="poll__span">Отлично</span>
                </div>
            </div>
            <div class="comment w-50" v-else>
                <div class="poll__feedback selected">
                    <span class="poll__text-error">Коментарий обязателен, без него мы не узнаем, как стать лучше</span>
                    <span class="poll__text font-weight-bold">{{ company.page.thanks }}</span>
                    <span class="poll__text">{{ company.page.text }}</span>
                    <textarea class="poll__textarea" placeholder="Текст сообщения" v-model="comment"></textarea>
                </div>
                <div class="poll__form selected">
                    <span class="poll__text">Сделано в <a class="poll__link" target="blank" :href="url">Reviewws.me</a></span>
                    <button class="poll__button selected" @click="vote()"
                            v-if="comment.length > 0 || !company.page.comment">Отправить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let toastr = require('toastr');

export default {
    data() {
        return {
            company: null,
            templates: null,
            popup: false,
            success: false,
            url: null,
            rating: 0,
            comment: '',
        }
    },
    mounted() {
        this.url = window.widget_url;
        this.init();
    },
    computed: {},
    methods: {
        messageBox(type, caption, message) {
            let options =
                {
                    "closeButton": true,
                    "debug": false,
                    "newestOnTop": false,
                    "progressBar": true,
                    "positionClass": this.toastrClass(),
                    "preventDuplicates": false,
                    "onclick": null,
                    "showDuration": "300",
                    "hideDuration": "1000",
                    "timeOut": "5000",
                    "extendedTimeOut": "1000",
                    "showEasing": "swing",
                    "hideEasing": "linear",
                    "showMethod": "fadeIn",
                    "hideMethod": "fadeOut"
                };
            if (type == "success")
                toastr.success(message, caption, options);
            else if (type == "error")
                toastr.error(message, caption, options);
        },

        toastrClass() {
            return window.innerWidth > 768 ? "toast-top-full-width" : "toast-bottom-full-width";
        },
        init() {
            let self = this;
            let data = {
                domain: window.location.hostname,
                path: window.location.origin + window.location.pathname,
            };
            this.$http.post(window.widget_url + '/api/v1/widget/material/init', data, {responseType: 'json'}).then(function (response) {
                self.company = response.body.company;
                self.templates = response.body.templates;
            }, response => {
                let index = 1;
                let message = [];
                let idx, idx2;

                for (idx in response.body.errors) {
                    for (idx2 in response.body.errors[idx]) {
                        message.push(response.body.errors[idx][idx2])
                    }
                }
                console.log("ОШИБКА: reviewss.me", message.join("\n"))
            });
        },
        choiceRating(rating) {
            this.rating = rating
        },
        vote() {
            let self = this;
            let data = {
                domain: window.location.hostname,
                rate: this.rating,
                comment: this.comment,
                url: window.location.href
            };
            this.$http.post(window.widget_url + '/api/v1/widget/material/vote', data, {responseType: 'json'}).then(function (response) {
                this.success = true
            }, response => {
                let message = [];
                let idx, idx2;
                for (idx in response.body.errors) {
                    for (idx2 in response.body.errors[idx]) {
                        message.push(response.body.errors[idx][idx2])
                    }
                }
                console.log("ОШИБКА: reviewss.me", message.join("\n"))
                self.isSend = false;
            });
        }
    }
}
</script>
<style>
.comment {
    width: 100%;
}
</style>
