//require('./bootstrap');
require('bootstrap');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);
require('toastr');

window.widget_url = 'https://reviewss.me/'
// window.widget_url = 'http://127.0.0.1:8000/'

// Vue.component('welcome', require('./components/WelcomeComponent').default);

let style = document.createElement('link');
style.rel = 'stylesheet';
style.type = 'text/css';
// style.href = 'https://reviewss.me/cdn/widget.min.css',
style.href = window.widget_url + 'cdn/widget.min.css',
    document.body.appendChild(style);

let frontend = null;

if (document.getElementById("reviewss") !== null) {
    let rating = Vue.component('rating', require('./components/WelcomeComponent').default);

    frontend = new Vue({
        el: '#reviewss',
        components: {
            'rating': rating
        }
    });
}

if (document.getElementById("reviewss-me-site") !== null) {
    let site = Vue.component('reviewss-me-site', require('./components/WelcomeSiteComponent').default);

    frontend = new Vue({
        el: '#reviewss-me-site',
    });
}

if (document.getElementById("reviewss-me-material") !== null) {
    let site = Vue.component('reviewss-me-material', require('./components/WelcomeMaterialComponent').default);

    frontend = new Vue({
        el: '#reviewss-me-material',
    });
}
